import { createAction, props } from '@ngrx/store';

import { Warning } from '../../models';

export const loadRequest = createAction(
  '[Warnings] Load Request',
  props<{ withDisabled?: boolean; property_id?: number[] }>(),
);

export const loadSuccess = createAction(
  '[Warnings] Load Success',
  props<{ items: Warning[] }>(),
);

export const loadFailure = createAction(
  '[Warnings] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Warnings] Create Request',
  props<{
    request: Omit<Warning, 'id'>;
  }>(),
);

export const createSuccess = createAction(
  '[Warnings] Create Success',
  props<{ item: Warning }>(),
);

export const createFailure = createAction(
  '[Warnings] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Warnings] Update Request',
  props<{
    request: Warning;
  }>(),
);

export const updateSuccess = createAction(
  '[Warnings] Update Success',
  props<{ item: Warning }>(),
);

export const updateFailure = createAction(
  '[Warnings] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Warnings] Delete Request',
  props<{
    itemId: number;
    onSuccess?: () => void;
  }>(),
);

export const deleteSuccess = createAction(
  '[Warnings] Delete Success',
  props<{ itemId: number }>(),
);

export const deleteFailure = createAction(
  '[Warnings] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Warnings] Reset State');
